<template>
  <div class='order-index'
       ref="orderIndexRef">
    <a-form :model="searchForm">
      <a-form-item class="mb-8">
        <a-radio-group v-model:value="searchForm.orderStatus"
                       @change="ChangeOrderStatus"
                       button-style="solid">
          <a-radio-button value="">全部</a-radio-button>
          <a-radio-button value="created">待支付</a-radio-button>
          <a-radio-button value="paid">已支付</a-radio-button>
        </a-radio-group>
      </a-form-item>
    </a-form>
    <div class="order-statistics mb-8">
      累计订单数 <span class="text-warning">{{ pagination.total }}</span> 笔，累计金额 <span
            class="text-danger">{{sumAmount}}</span>
      元
    </div>
    <a-table :columns="columns"
             :data-source="orders"
             :pagination="pagination"
             :scroll="{ x: 2000 }"
             row-key="id"
             @change="onPaginationChange"
             :loading="tableLoading">
      <template #customer="{ record }">
        <span>{{record.customerName}}</span>
        <span class="name-suffix__wechat"
              v-if="record.customerType === userTypeEnum.WECHAT">@微信</span>
        <span class="name-suffix__corp"
              v-if="record.customerType === userTypeEnum.CORP">@{{record.corpName}}</span>
        <p class="mv4">姓名：{{record.customerRealName}}</p>
        <p>手机号：{{record.customerMobile}}</p>
      </template>
      <template #payAmount="{record}">
        <span>{{record.payAmount}}</span>
      </template>
      <template #tags="{ record }">
        <a-tag v-for="(tag, index) in record.tags"
               :key="index">{{ tag.name }}</a-tag>
      </template>

      <template #status="{ record }">
        <span v-if="record.orderStatus === 'created'">待支付</span>
        <span v-if="['paid', 'success'].includes(record.orderStatus)">已支付</span>
      </template>

      <template #protocol="{ record }">
        <span class="color-primary text-overflow cursor-pointer"
              @click="openProtocolModal(record.protocolId)">{{record.protocolName}}</span>
      </template>

      <template #createdTime="{ record }">
        {{ $f.datetime(record.createdTime, 'YYYY-MM-DD HH:mm') }}
      </template>

      <template #payTime="{ record }">
        <span v-if="record.payTime === 0"></span>
        <span v-else>{{ $f.datetime(record.payTime, 'YYYY-MM-DD HH:mm') }}</span>
      </template>
      <template #action="{ record }">
        <a-button type="link"
                  @click="showOrderDetail(record.id)">查看</a-button>
      </template>
    </a-table>

    <order-detail v-model:visible="
                           orderDetailVisible"
                  :orderDetail="orderDetail">
    </order-detail>

    <a-modal v-model:visible="protocolModalVisible"
             class="protocol-modal"
             title="协议详情"
             :width="600"
             :getContainer="() => orderIndexRef"
             :footer="null">
      <div class="pb24 is-flex">
        <div class="protocol__label">协议名称:</div>
        <div class="protocol__value">{{protocolDetail.name}}</div>
      </div>
      <div class="pb24 is-flex">
        <div class="protocol__label">协议内容:</div>
        <div class="protocol-content"
             v-html="protocolDetail.protocol"></div>
      </div>
    </a-modal>

  </div>
</template>

<script>
import { Radio, Modal } from "ant-design-vue";
import { reactive, ref, onMounted, h } from "vue";
import _ from "lodash";
import { useRoute, useRouter } from "vue-router";

import orderApi from "@/service/api/order";
import { userTypeEnum } from "@/global";

import OrderDetail from "@/views/goods/OrderDetail";

export default {
  name: "MemberOrderList",
  components: {
    ARadioGroup: Radio.Group,
    ARadioButton: Radio.Button,

    OrderDetail,
  },
  data() {
    const DATAFORMAT = "YYYY/MM/DD";
    return {
      DATAFORMAT,
      orderDetail: {
        corpId: 0,
        cover: "",
        goodsId: 0,
        specsId: 0,
        goodsName: "",
        customerType: 1,
        customerName: "",
        customerAvatar: "",
        customerMobile: "",
        orderSn: "",
        orderStatus: "",
        isPaid: 0,
        createdTime: "",
        payTime: "",
        summary: "",
        payAmount: 0,
        staffName: "",
        staffAvatar: "",
        corpTags: [],
      },
    };
  },
  setup() {
    const $route = useRoute();
    const $router = useRouter();
    const orderDetailVisible = ref(false);
    const searchForm = reactive({
      orderStatus: "",
      staffId: $route.query.id,
    });
    const sumAmount = ref(0);
    const columns = [
      {
        title: "订单编号",
        dataIndex: "orderSn",
        fixed: "left",
        width: 220,
      },
      {
        title: "客户",
        dataIndex: "customerName",
        slots: { customRender: "customer" },
      },
      {
        title: "所属成员",
        dataIndex: "staffName",
        slots: { customRender: "staffName" },
        width: 150,
      },
      {
        title: "订单价格(元)",
        dataIndex: "payAmount",
        width: 130,
      },
      {
        title: "原价(元)",
        dataIndex: "priceAmount",
        width: 130,
      },
      {
        title: "商品名称",
        dataIndex: "goodsName",
      },

      {
        title: "订单状态",
        dataIndex: "orderStatus",
        slots: { customRender: "status" },
        width: 130,
      },
      {
        title: "用户协议",
        dataIndex: "protocolName",
        slots: { customRender: "protocol" },
      },
      {
        title: "创建时间",
        dataIndex: "createdTime",
        width: 120,
        slots: { customRender: "createdTime" },
      },
      {
        title: "支付时间",
        dataIndex: "payTime",
        width: 120,
        slots: { customRender: "payTime" },
      },
      {
        title: "备注",
        dataIndex: "remark",
      },
      {
        title: "操作",
        key: "action",
        fixed: "right",
        width: 100,
        slots: { customRender: "action" },
      },
    ];

    const unassociatedOrder = () => {
      Modal.warning({
        class: "unassociated",

        title: "提示",
        content: h("div", {}, [
          h(
            "p",
            "尚未关联EduSoho网校，请用微信扫下面二维码，联系阔知网络科技有限公司客服人员。"
          ),
          h("img", {
            class: "unassociated__img",
            src: "https://dss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=3700526141,2671696428&fm=224&gp=0.jpg",
            width: 200,
            height: 200,

            style: "display: block; margin: 0 auto; margin-top: 8px;",
          }),
        ]),
        width: 480,
        closable: true,
        maskClosable: true,
      });
    };

    const notOPenPayOrder = () => {
      Modal.warning({
        title: "提示",
        content: "尚未开启微信支付，请联系管理员开启。",
        width: 480,
        closable: true,
        maskClosable: true,
      });
    };

    const ChangeOrderStatus = async () => {
      searchForm.staffId = $route.query.id;
      sumAmount.value = await orderApi.sumPayAmount(searchForm);
      onSearchSubmit();
      fetchPaginationData();
    };

    const tableLoading = ref(false);
    const rows = ref([]);
    const pagination = reactive({
      current: 1,
      pageSize: 20,
      total: 0,
      pageSizeOptions: ["10", "20", "50"],
      showSizeChanger: true,
      showTotal: (total) => `共 ${total} 条`,
      showQuickJumper: true,
    });

    const onSearchSubmit = async () => {
      pagination.current = 1;

      const query = _.assign({}, searchForm, {
        id: $route.query.id,
        staffId: $route.query.id,
        page: _.toString(pagination.current),
        size: _.toString(pagination.pageSize),
      });

      if (_.isEqual($route.query, query)) {
        await fetchPaginationData();
      } else {
        await $router.push({ name: $route.name, query: query });
      }
    };
    const pullQueryParams = (query) => {
      for (let key in searchForm) {
        searchForm[key] = query[key] ? query[key] : "";
      }

      if (query.page) {
        pagination.current = _.toInteger(query.page);
      }

      if (query.size) {
        pagination.pageSize = _.toInteger(query.size);
      }
    };

    const fetchPaginationData = async () => {
      tableLoading.value = true;
      const response = await orderApi.search({
        orderStatus: searchForm.orderStatus,
        staffId: $route.query.id,
        page: pagination.current - 1,
        size: pagination.pageSize,
      });
      rows.value = response.data;
      pagination.total = response.total;
      tableLoading.value = false;
    };

    const onPaginationChange = async (pagination, filters, sorter) => {
      let sortParams = {};
      if (sorter.field && sorter.order) {
        sortParams = {
          sort:
            sorter.field + "," + (sorter.order === "ascend" ? "asc" : "desc"),
        };
      } else {
        sortParams = { sort: "" };
      }

      const query = _.assign(
        {},
        $route.query,
        { page: pagination.current, size: pagination.pageSize },
        sortParams
      );

      pullQueryParams(query);
      const response = await orderApi.search({
        orderStatus: searchForm.orderStatus,
        staffId: $route.query.id,
        page: pagination.current - 1,
        size: pagination.pageSize,
      });
      rows.value = response.data;
      pagination.total = response.total;
      $router.push({ name: $route.name, query: query });
    };

    // 订单协议
    const orderIndexRef = ref();
    const protocolModalVisible = ref(false);
    const protocolDetail = reactive({
      name: "",
      protocol: "",
    });
    async function openProtocolModal(id) {
      protocolModalVisible.value = true;
      const res = await orderApi.getProtocol({ id: id });
      _.assign(protocolDetail, res);
    }

    onMounted(async () => {
      sumAmount.value = await orderApi.sumPayAmount(searchForm);
      pullQueryParams($route.query);
      await fetchPaginationData();
    });

    return {
      userTypeEnum,
      orderDetailVisible,
      searchForm,
      columns,
      orders: rows,
      sumAmount,
      unassociatedOrder,
      notOPenPayOrder,
      pagination,
      pullQueryParams,
      fetchPaginationData,
      ChangeOrderStatus,
      onPaginationChange,
      tableLoading,

      orderIndexRef,
      protocolModalVisible,
      protocolDetail,
      openProtocolModal,
    };
  },

  computed: {},

  methods: {
    async showOrderDetail(orderId) {
      this.orderDetail = await orderApi.getOrder({ orderId: orderId });
      this.orderDetailVisible = true;
    },
  },
};
</script>
<style lang='less' scoped>
.protocol-modal {
  .protocol__label {
    width: 70px;
    margin-right: 16px;
    color: @gray-dark;
    .text-14();
  }

  .protocol__value {
    color: @gray-darker;
  }

  .protocol-content {
    padding: 16px;
    width: 440px;
    height: 240px;
    overflow-y: auto;
    background-color: @bg-normal;
  }
}
</style>
