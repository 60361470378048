<template>
  <a-modal class="order-detail"
           title="订单详情"
           centered
           :width="600"
           :bodyStyle="{height: '620px', overflowY: 'auto'}"
           :footer="null">
    <div class="pb6 is-flex">
      <div :span="4"
           class="order-detail__label">创建时间:</div>
      <div :span="20"
           class="order-detail__value">{{ orderDetail.createdTime }}</div>
    </div>
    <div class="pb6 is-flex">
      <div :span="4"
           class="order-detail__label">订单编号:</div>
      <div :span="20"
           class="order-detail__value">{{ orderDetail.orderSn }}</div>
    </div>
    <div class="pb6 is-flex">
      <div :span="4"
           class="order-detail__label">商品名称:</div>
      <div :span="20"
           class="order-detail__value">{{ orderDetail.goodsName }}</div>
    </div>
    <div class="pb6 is-flex">
      <div :span="4"
           class="order-detail__label">订单价格:</div>
      <div :span="20"
           class="order-detail__value">{{ orderDetail.payAmount / 100 }}元</div>
    </div>
    <div class="pb6 is-flex">
      <div :span="4"
           class="order-detail__label">原价:</div>
      <div :span="20"
           class="order-detail__value">{{ orderDetail.priceAmount / 100 }}元</div>
    </div>
    <div class="pb6 is-flex">
      <span :span="4"
            class="order-detail__label">图片:</span>
      <span :span="20"
            class="order-detail__value">
        <a-image :width="120"
                 :src="orderDetail.cover" />
      </span>
    </div>
    <div class="pb6 is-flex">
      <div :span="4"
           class="order-detail__label">简介:</div>
      <div :span="20"
           class="order-detail__value ck-content"
           v-html="orderDetail.summary"></div>
    </div>
    <div class="pb6 is-flex">
      <div :span="4"
           class="order-detail__label">客户:</div>
      <div class="order-detail__value is-flex has-avatar">
        <avatar-column :avatar="{avatar: orderDetail.customerAvatar, name: orderDetail.customerName, corpName: orderDetail.customerCorpName}"
                       :userType="orderDetail.customerType"
                       round
                       :size="64">
          <template #bottom>
            <p>姓名：{{ orderDetail.customerRealName }}</p>
            <p>手机号：{{ orderDetail.customerMobile }} </p>
          </template>
        </avatar-column>

      </div>
    </div>
    <div v-if="orderDetail.staffName"
         class="pb6 is-flex ">
      <span :span="4"
            class="order-detail__label">员工:</span>
      <div class="order-detail__value has-avatar">
        <avatar-column :avatar="{avatar: orderDetail.staffAvatar, userId: orderDetail.staffWorkWechatThirdAppUserId }"
                       round
                       :size="40"
                       is-user-id />
      </div>
    </div>
  </a-modal>
</template>

<script>
import { defineComponent } from "vue";
import { Modal, Image } from "ant-design-vue";

import "@/styles/components/content-styles.less";
import AvatarColumn from "../../components/AvatarColumn.vue";

export default defineComponent({
  name: "OrderDetail",
  props: ["orderDetail"],
  emits: [],
  components: {
    AImage: Image,
    AModal: Modal,
    // AAvatar: Avatar,
    AvatarColumn,
  },
});
</script>
<style lang='less' scoped>
.order-detail {
  .pb6 {
    padding-bottom: @spacing-6x;
  }

  &__label {
    display: inline-block;
    width: 70px;
    margin-right: @spacing-4x;
    .text-14();
    text-align: right;
    color: @gray;
    vertical-align: top;
  }

  &__value {
    max-width: 466px;
    color: @gray-darker;

    .ant-avatar {
      margin-right: @spacing-3x;
    }

    &.has-avatar {
      align-items: center;
      padding: @spacing-2x @spacing-4x;
      border: 1px solid @border;
      border-radius: @border-radius;
      .text-14();
      color: @gray-darker;
      background-color: @bg-normal;
    }

    .ant-tag {
      margin-bottom: 8px;
    }
  }

  .ck-content {
    max-height: 400px;
    overflow-y: auto;
  }
}
</style>