import {apiClient as client} from '@/shared/service/api-client';

export default {
  async search(params) {
     return await client.get('/order/search', {params: params});
  },
  async sumPayAmount(params) {
    return await client.get('/order/sumPayAmount', {params: params});
  },
  async share(orderId) {
    return await client.post('/order/share', {id: orderId});
  },
  async delete(orderId) {
    return await client.post('/order/close', {id: orderId});
  },
  async getOrder(params) {
    return await client.get('/order/getOrder', {params: params});
  },
  async editRemark(params) {
    return await client.post('/order/editRemark', params);
  },
  async exportOrders(params) {
    return await client.get('/order/exportOrders', {params: params});
  },
  async getProtocol(params) {
    return await client.get('/orderProtocol/getProtocol', {params: params});
  },
}
